var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"panel panel-inverse",attrs:{"data-sortable-id":"form-stuff-1"}},[_c('div',{staticClass:"panel-body"},[_vm._m(0),_c('vxe-toolbar',{ref:"xToolbar",attrs:{"loading":_vm.loading,"custom":"","zoom":"","print":"","export":""},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-input',{staticStyle:{"width":"250px"},attrs:{"size":"mini","type":"text","placeholder":"请输入新闻标题查询查询"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.NewNamekeyupEvent.apply(null, arguments)}},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}}),_c('vxe-button',{attrs:{"size":"mini","icon":"fa fa-search","circle":""},on:{"click":_vm.NewNamekeyupEvent}}),_c('vxe-button',{attrs:{"size":"mini","status":"primary","icon":"fa fa-plus","content":"新增"},on:{"click":_vm.insertEvent}})]},proxy:true}])}),_c('vxe-table',{ref:"xTable",attrs:{"round":"","border":"","height":_vm.tabelHeight,"loading":_vm.loading,"data":_vm.tableData,"print-config":{},"import-config":{},"export-config":{},"column-config":{ resizable: true }}},[_c('vxe-column',{attrs:{"field":"orderNum","title":"序号","width":"100","align":"center"}}),_c('vxe-column',{attrs:{"field":"title","title":"标题","width":"500","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editEvent(row)}}},[_vm._v(" "+_vm._s(row.title)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"creationTime","title":"创建时间","formatter":_vm.formattercreationTime}}),_c('vxe-column',{attrs:{"title":"操作","fixed":"right","width":"250","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-button',{staticClass:"m-r-5",attrs:{"size":"mini","icon":"fa fa-wrench","status":"primary"},on:{"click":function($event){return _vm.editEvent(row)}}},[_vm._v("编辑 ")]),_c('vxe-button',{attrs:{"size":"mini","status":"warning","icon":"fa fa-trash-alt"},on:{"click":function($event){return _vm.removeRowEvent(row)}}},[_vm._v("删除")])]}}])})],1),_c('vxe-pager',{attrs:{"border":"","icon-prev-page":"fa fa-angle-left","icon-jump-prev":"fa fa-angle-double-left","icon-jump-next":"fa fa-angle-double-right","icon-next-page":"fa fa-angle-right","icon-jump-more":"fa fa-ellipsis-h","loading":_vm.loading,"current-page":_vm.tablePage.currentPage,"page-size":_vm.tablePage.pageSize,"total":_vm.tablePage.totalResult,"layouts":[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total' ]},on:{"page-change":_vm.handlePageChange}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"page-header"},[_vm._v(" 政策新闻 "),_c('small',[_vm._v("国家法律法规、安全新闻知识等...")])])}]

export { render, staticRenderFns }