import {
    propertyService
} from '@/api/utils/request'
const apiUrl = {
    //获取企业列表
    GetNewsList: '/api/article/gets',
    CreateNews: '/api/article/create',
    UpdateNews: '/api/article/update',
    GetNews:'/api/article/get',
    DeleteNews:'/api/article/delete',
}

const newsApi = {
    //获取新闻列表
    GetNewsList(parameter) {
        return propertyService({
            url: apiUrl.GetNewsList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //新建一个政策新闻
    CreateNews(parameter) {
        return propertyService({
            url: apiUrl.CreateNews,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
        //获取一个政策新闻
        GetNews(parameter) {
            return propertyService({
                url: apiUrl.GetNews,
                method: 'get',
                params: parameter,
                needToken: true
            })
        },
    //修改一个政策新闻
    UpdateNews(id,parameter) {
        return propertyService({
            url: apiUrl.UpdateNews+'?id='+id,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },    //删除一个政策新闻
    DeleteNews(parameter) {
        return propertyService({
            url: apiUrl.DeleteNews,
            method: 'delete',
            params: parameter,
            needToken: true
        })
    },
}


export {
    newsApi
}